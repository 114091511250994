<template>
  <div class="minePage">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
      <div class="userInfoBox">
        <router-link tag="div" to="/userHomePage" class="avatarBox" :class="{ isCertifica: userInfo.merchantUser == 1 }">
          <ImgDecypt class="avatarImg" round :src="userInfo.portrait" :key="userInfo.portrait" />
          <div class="certification" v-if="userInfo.superUser">
            <Vlogo :vid="userInfo.uid" />
          </div>
          <!-- 认证商家 -->
          <!-- <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="userInfo.merchantUser == 1"/> -->
        </router-link>
        <div class="userInfo">
          <div class="userNameBox">
            <div class="userName">
              <router-link tag="span" to="/userHomePage" :class="{ vipName: userInfo.isVip && userInfo.vipLevel > 0 }">
                {{ userInfo.name }}
              </router-link>
              <router-link tag="div" to="/personalInfo">
                <svg-icon class="editBtn" iconClass="setting" />
              </router-link>
            </div>
            <router-link tag="div" to="/userHomePage" class="homeBtn">
              <span>主页</span>
              <svg-icon class="rightArrow" iconClass="right_arrow" />
            </router-link>
          </div>
          <div class="info">
            <svg-icon class="vip" iconClass="unVip" v-if="userInfo.isVip && userInfo.vipLevel == 0" />
            <svg-icon class="vip" iconClass="vip" v-else-if="userInfo.isVip && userInfo.vipLevel == 1" />
            <svg-icon class="vip" iconClass="svip" v-else-if="userInfo.isVip && userInfo.vipLevel == 2" />
          </div>
        </div>
      </div>
      <div class="userData">
        <div class="data">
          <span>{{ userInfo.like | watchCount }}</span>
          <span>获赞</span>
        </div>
        <div class="verticalLine"></div>
        <router-link tag="div" to="/focusOn" class="data">
          <span>{{ userInfo.follow | watchCount }}</span>
          <span>关注</span>
        </router-link>
        <div class="verticalLine"></div>
        <router-link tag="div" to="/fans" class="data">
          <span>{{ userInfo.fans | watchCount }}</span>
          <span>粉丝</span>
        </router-link>
      </div>
      <div class="memberWallet">
        <router-link tag="div" to="/memberCentre?t=vip" class="box leftBox">
          <svg-icon class="icon" iconClass="vip_logo" />
          <div class="indro">
            <div class="title">{{ userInfo.isVip ? showVipType(userInfo.vipLevel) : '充值会员' }}</div>
            <div>{{ userInfo.isVip ? '有效期:' + formatDate(userInfo.vipExpireDate) : '畅享海量内容' }}</div>
          </div>
        </router-link>
        <router-link tag="div" to="/myWallet" class="box rightBox">
          <svg-icon class="icon" iconClass="wallet_logo" />
          <div class="indro">
            <div class="title">我的钱包</div>
            <div>余额:{{ walletInfo.amount || 0 }}</div>
          </div>
        </router-link>
      </div>
      <van-grid column-num="4" :border="false" class="funcBox">
        <van-grid-item class="funcItem" @click="$router.push('/creationCenter')">
          <svg-icon class="funcIcon" iconClass="creationCenter" />
          <div class="funcTitle">创作中心</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/myCertification')">
          <svg-icon class="funcIcon" iconClass="myCertification" />
          <div class="funcTitle">我的认证</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/incomeCenter')">
          <svg-icon class="funcIcon" iconClass="myIncome" />
          <div class="funcTitle">我要赚钱</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/myPurchases')">
          <svg-icon class="funcIcon" iconClass="purchases" />
          <div class="funcTitle">我的购买</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/exchangeMember')">
          <svg-icon class="funcIcon" iconClass="exchgVip" />
          <div class="funcTitle">兑换会员</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/myCollection')">
          <svg-icon class="funcIcon" iconClass="myLike" />
          <div class="funcTitle">我的喜欢</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/taskHall')">
          <svg-icon class="funcIcon" iconClass="task" />
          <div class="funcTitle">任务大厅</div>
        </van-grid-item>
        <van-grid-item class="funcItem" @click="$router.push('/kefu')">
          <svg-icon class="funcIcon" iconClass="customerService" />
          <div class="funcTitle">在线客服</div>
        </van-grid-item>
        <!-- <van-grid-item class="funcItem" @click="$router.push('/information')">
        <svg-icon class="funcIcon" iconClass="myInformation" />
        <div class="funcTitle">我的消息</div>
        <div class="dot" v-if="(unreadMessages.msgCount + unreadMessages.dtCount) > 0"></div>
      </van-grid-item> -->
      </van-grid>
      <!-- <router-link tag="div" to="/myCollection" class="cell">
      <svg-icon class="cellIcon" iconClass="myLike" />
      <div class="cellTitle">我的喜欢</div>
      <svg-icon class="right_arrow" iconClass="right_arrow" />
    </router-link> -->
      <div class="cellBox">
        <router-link tag="div" to="/historyRecord" class="cell">
          <svg-icon class="cellIcon" iconClass="history" />
          <div class="cellTitle">历史记录</div>
          <svg-icon class="right_arrow" iconClass="right_arrow" />
        </router-link>
        <!-- <router-link tag="div" to="/wishList" class="cell">
        <svg-icon class="cellIcon" iconClass="qiupian" />
        <div class="cellTitle">我要求片</div>
        <svg-icon class="right_arrow" iconClass="right_arrow" />
      </router-link> -->
        <router-link tag="div" to="actCredentials" class="cell">
          <svg-icon class="cellIcon" iconClass="mine_act" />
          <div class="cellTitle">账号凭证</div>
          <svg-icon class="right_arrow" iconClass="right_arrow" />
        </router-link>
        <router-link tag="div" to="/officialCommunity" class="cell">
          <svg-icon class="cellIcon" iconClass="community" />
          <div class="cellTitle">官方社群</div>
          <svg-icon class="right_arrow" iconClass="right_arrow" />
        </router-link>
        <router-link tag="div" to="/boutiqueApp" class="cell">
          <svg-icon class="cellIcon" iconClass="application" />
          <div class="cellTitle">精品应用</div>
          <svg-icon class="right_arrow" iconClass="right_arrow" />
        </router-link>
        <router-link tag="div" to="/personalInfo" class="cell">
          <svg-icon class="cellIcon" iconClass="setting2" />
          <div class="cellTitle">设置中心</div>
          <svg-icon class="right_arrow" iconClass="right_arrow" />
        </router-link>
      </div>
      <!-- 账号凭证弹窗 -->
      <div class="actTip">
        <van-overlay :show="showActTip">
          <div class="actTipBox">
            <div class="info">为了避免账号丢失，请保存账号凭证！</div>
            <div class="actTipBtn">
              <div class="btn" @click="saved">下次再说</div>
              <div class="btn pink" @click="$router.push('/actCredentials')">立即保存</div>
            </div>
            <div class="close" @click="showActTip = !showActTip">
              <svg-icon icon-class="close4" class="closeIcon" />
            </div>
          </div>
        </van-overlay>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import { Grid, GridItem, Overlay, PullRefresh } from 'vant';
import { mapGetters } from 'vuex';
export default {
  name: 'mine',
  components: {
    Vlogo,
    ImgDecypt,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Overlay.name]: Overlay,
    [PullRefresh.name]: PullRefresh,
  },

  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      unreadMessages: 'unreadMessages',
      walletInfo: 'walletInfo',
    }),
  },
  activated() {
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('user/getWallet');
  },
  data() {
    return {
      showActTip: localStorage.getItem('isCertificate') == 0 ? false : true,
      isLoading: false,
    };
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('user/getWallet');
  },
  methods: {
    async onRefresh() {
      await this.$store.dispatch('user/getUserInfo');
      await this.$store.dispatch('user/getWallet');
      this.isLoading = false;
    },
    // 校验时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 显示vip类型
    showVipType(level) {
      let vipStr = '';
      if (level > 1) {
        vipStr = '超级会员';
      } else {
        vipStr = '普通会员';
      }
      return vipStr;
    },
    // 日期
    formatDate(date) {
      if (!date) return 0;
      let dateago = new Date(date);
      return dateago.getFullYear() + '年' + (dateago.getMonth() + 1) + '月' + dateago.getDate() + '日';
    },

    saved() {
      localStorage.setItem('isCertificate', '0');
      this.showActTip = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.minePage {
  height: 100%;
  /deep/ .van-pull-refresh {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .userInfoBox {
    height: 49px;
    // margin: 0 0 15px;
    padding: 30px 12px 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    .avatarBox {
      position: relative;
      .avatarImg {
        width: 63px;
        height: 63px;
        border-radius: 50%;
      }
      .certification {
        width: 15.1px;
        height: 15.2px;
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }
      .certificaBorder {
        position: absolute;
        top: -8.2px;
        width: 100%;
        font-size: 25px;
        z-index: 1;
      }
    }
    .isCertifica {
      border-radius: 50%;
      border: 1.5px solid #d82d0b;
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      width: 100%;
      .userNameBox {
        flex: 2;
        display: flex;
        justify-content: space-between;
        .userName {
          font-size: 18px;
          margin-left: 6px;
          display: flex;
          align-items: center;
          color: rgb(51, 51, 51);
          .editBtn {
            width: 22px;
            height: 22px;
            vertical-align: -0.28em;
            margin-left: 8px;
          }
          .vipName {
            color: #ff678f;
          }
        }
        .homeBtn {
          display: flex;
          align-items: center;
          color: rgb(102, 102, 102);
          span {
            font-size: 16px;
          }
          .rightArrow {
            width: 20px;
            height: 20px;
            margin-left: 3px;
          }
        }
      }
      .info {
        .vip {
          height: 24.7px;
          width: 61px;
          margin-left: 3px;
        }
      }
    }
  }
  .userData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 12px 7px;
    background-color: #fff;
    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      span:first-of-type {
        font-size: 18px;
        font-weight: 800;
        color: rgb(68, 68, 68);
      }
      span:nth-of-type(2) {
        font-size: 12px;
        margin-top: 2px;
        color: rgb(153, 153, 153);
      }
    }
    .verticalLine {
      height: 23px;
      width: 1px;
      background-color: rgb(226, 226, 226);
    }
  }
  .memberWallet {
    display: flex;
    justify-content: space-between;
    margin: 6px 0 6px;
    padding: 7px 12px;
    background: #fff;
    .box {
      padding: 0 14px;
      display: flex;
      align-items: center;
      background-color: rgb(255, 235, 241);
      border-radius: 4px;
      height: 64px;
      .icon {
        height: 32px;
        width: 32px;
      }
      .indro {
        margin-left: 6px;
        font-size: 11.5px;
        color: rgb(255, 88, 132);
        .title {
          font-size: 14px;
          font-weight: 800;
        }
      }
    }
    .leftBox {
      flex: 1;
    }
    .rightBox {
      width: 122px;
      margin-left: 10px;
    }
  }
  .funcBox {
    background: rgb(255, 255, 255);
    margin-bottom: 10px;
    color: rgb(51, 51, 51);
    padding: 5px 15px;
    ::deep .minPage {
      padding: unset;
    }
    .funcItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      /deep/ .van-grid-item__content {
        background: none;
        padding: 12px 0px;
      }
      .funcIcon {
        width: 38px;
        height: 38px;
        margin-bottom: 4px;
      }
      .funcTitle {
        font-size: 14px;
      }
      .dot {
        height: 10px;
        width: 10px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        top: 10px;
      }
    }
  }
  .cellBox {
    background-color: #fff;
    padding: 16px 26px 6px;
    .cell {
      height: 41px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .cellIcon {
        width: 24px;
        height: 24px;
      }
      .cellTitle {
        flex: 2;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: rgb(51, 51, 51);
      }
      .right_arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
  .actTip {
    /deep/ .van-overlay {
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .actTipBox {
      width: 318px;
      height: 233px;
      background: url('../../assets/png/actTip.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      margin-bottom: 10%;
      position: relative;
      .info {
        width: 100%;
        font-size: 16px;
        color: black;
        position: absolute;
        top: 117px;
      }
      .actTipBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 17px;
        box-sizing: border-box;
        position: absolute;
        bottom: 20px;
        .btn {
          width: 136px;
          height: 38px;
          line-height: 38px;
          font-size: 16px;
          border-radius: 28px;
          color: rgb(102, 102, 102);
          border: 1px solid rgb(102, 102, 102);
        }
        .pink {
          border: 1px solid rgb(255, 103, 143);
          color: rgb(255, 103, 143);
        }
      }

      .openVip {
        width: 252px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        background: #ff9f19;
        border-radius: 6px;
        margin: 20px auto;
        font-size: 14px;
        box-shadow: 4px 4px 12px rgba(#ff7527, 0.5);
      }

      .share {
        text-align: center;
        color: #425453;
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 4px;
        border-bottom: 0.5px solid rgba(#425453, 0.6);
      }

      .close {
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translate(-50%, 0);
        .closeIcon {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
}
</style>
